<template>
  <div class="login">
    <h2>后台登录</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">用户名:</label>
        <input type="text" id="username" v-model="username" required>
      </div>
      <div class="form-group">
        <label for="password">密码:</label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <button type="submit">登录</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'loginIndex',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login(){
      let data = {
        account: this.username,
        password: this.password
      }
      axios.post(
          'https://pet.api.shbonet.com/api/v1/Login.serverLogin?app_id=68b4ba1061&identity=4a5ba5fe3a9462cef2e4a08de77f62a6',
          data).then (res => {
        console.log(res)
        if(res.data.code == 200) {
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('account', res.data.data.account)
          this.$router.push('/chat')
        }
      }).catch(error => {
        // 处理登录失败的情况
        console.log(error)
        alert('登录失败，请检查用户名和密码是否正确')
      })
    }
  }
}
</script>

<style scoped>
.login {
  max-width: 400px;
  margin: 400px auto;
}
.form-group {
  margin-bottom: 1em;
}
label {
  display: block;
}
input[type="text"], input[type="password"] {
  width: 100%;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid #ccc;
}
button[type="submit"] {
  padding: 0.5em 1em;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
}
</style>
